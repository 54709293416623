import React from 'react';
import { useTranslation } from "react-i18next";
import logo from '../../assets/images/logo.png';
import './style.scss';

const Footer = () => {
  const { t } = useTranslation();
  const { hostname } = document.location;
  
  return (
    <footer className='footer'>
      <div className='container'>
        <div className='footer_content'>
          <div className="left-side">
            <div className='logo-wrap'>
              <img src={logo} alt="Logo" className="header-logo" />
            </div>
            <div className='footer-nav'>
                <div className='item-block' >
                  <a href="tel:+441241340867" className='nav-title'>+441241340867</a>
                </div>
                <div className='item-block'>
                  <a href="/docs/PRIVACY_POLICY.pdf" target="_blank" className='nav-title'>
                    {t('footer.docs.privacy')}
                  </a>
                </div>
                <div className='item-block'>
                  <a href={`mailto:support@${hostname}`} className='nav-title'>
                    support@{hostname}
                  </a>
                </div>
                <div className='item-block'>
                  <a href="/docs/CONFLICT_OF_INTERESTS.pdf" target="_blank" className='nav-title'>
                    {t('footer.docs.conflicts_interests')}
                  </a>
                </div>
                <div className='item-block'>
                  <div className='nav-title'>170 Connell Crescent, London, W5 3BP</div>
                </div>
                <div className='item-block'>
                  <a href="/docs/CLIENT_AGREEMENT.pdf" target="_blank" className='nav-title'>
                    {t('footer.docs.client_agreement')}
                  </a>
                </div>
            </div>
          </div>
          <div className='right-side'>
            <div className='nav-title'>{t('footer.title')}</div>
            <div className='text'>
              <p>{t('footer.text')}</p>
              <p>{t('footer.text-1')}</p>
            </div>
            <div className='copyright'>{t('footer.copyright')}</div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;