import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import MainBottomBlock from '../MainBottomBlock/component';
import AboutSlider from '../AboutSlider/component';
import TextAccordion from '../BonusAcc/component';

import image1 from '../../assets/images/about/about-1.webp';
import image2 from '../../assets/images/about/about-2.webp';
import image3 from '../../assets/images/about/about-3.webp';
import image4 from '../../assets/images/about/about-4.webp';

const slides = [
  { 
    title: 'about-slider-1-title',
    text: 'about-slider-1-text',
    img: image1,
  },
  {
    title: 'about-slider-2-title',
    text: 'about-slider-2-text',
    img: image2,
  },
  {
    title: 'about-slider-3-title',
    text: 'about-slider-3-text',
    img: image3,
  },
  {
    title: 'about-slider-4-title',
    text: 'about-slider-4-text',
    img: image4,
  }
];

const tabs = {
  contentTab: 'about-history-tabs',
  title1: 'about-history-0',
  text1: 'about-history-1',
  text2: 'about-history-2',
};

function AboutUs() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Fix Flex · About Us</title>
        <meta name="title" content="Empower Your Trading Journey with Fix Flex" />
        <meta property="og:title" content="Empower Your Trading Journey with Fix Flex" />
        <meta property="twitter:title" content="Empower Your Trading Journey with Fix Flex" />

        <meta name="description" content="Explore our mission, values, and commitment to creating a user-centric trading environment. Discover our innovative journey and how we cultivate a collaborative trading community." />
        <meta property="og:description" content="Explore our mission, values, and commitment to creating a user-centric trading environment. Discover our innovative journey and how we cultivate a collaborative trading community." />
        <meta property="twitter:description" content="Explore our mission, values, and commitment to creating a user-centric trading environment. Discover our innovative journey and how we cultivate a collaborative trading community." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='bonus-page ovf-hidden'>
        <Header />
        <AboutSlider slides={slides} />

        <TextAccordion content={tabs} />
        <MainBottomBlock />
        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default AboutUs;
