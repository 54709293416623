import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import MainExp from '../Main_Experience/component';
import MainBottomBlock from '../MainBottomBlock/component';
import Accordion from '../Accordion/component';
import TableTabs from '../TableTabs/component';

import imageRight from '../../assets/images/acc_type-1.webp';

function AccounTypes() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Fix Flex · Account types</title>
        <meta name="title" content="Explore various account types at Fix Flex for your trading" />
        <meta property="og:title" content="Explore various account types at Fix Flex for your trading" />
        <meta property="twitter:title" content="Explore various account types at Fix Flex for your trading" />

        <meta name="description" content="Choose one of 4 account types at Fix Flex to make your trading strategy more efficient" />
        <meta property="og:description" content="Choose one of 4 account types at Fix Flex to make your trading strategy more efficient" />
        <meta property="twitter:description" content="Choose one of 4 account types at Fix Flex to make your trading strategy more efficient" />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='trading-page ovf-hidden'>
        <Header />
        <MainExp
          text1='account_type-0' 
          text3='account_type-1' 
          textbtn='account_type-btn'  
          imageRight={imageRight}
        />

        <TableTabs />
        <Accordion />

        <MainBottomBlock />
        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default AccounTypes;
