import React, {useState} from 'react';
import { useTranslation } from "react-i18next";
import arrow from '../../assets/images/arrow-tr.svg';
import icon0 from '../../assets/images/trading_img/stocks.webp';
import icon1 from '../../assets/images/trading_img/forex.webp';
import icon2 from '../../assets/images/trading_img/commodities.webp';
import icon3 from '../../assets/images/trading_img/cryptocurrencies.webp';
import icon4 from '../../assets/images/trading_img/indices.webp';
import icon5 from '../../assets/images/trading_img/bonds.webp';
import icon6 from '../../assets/images/trading_img/options.webp';
import icon7 from '../../assets/images/trading_img/futures.webp';
import './style.scss';

function TextImage() {
  const [activeBlock, setActiveBlock] = useState(null);

  const { t } = useTranslation();

  const icons = [
    {img: icon0, index: 0},
    {img: icon1, index: 1},
    {img: icon2, index: 2},
    {img: icon3, index: 3},
    {img: icon4, index: 4},
    {img: icon5, index: 5},
    {img: icon6, index: 6},
    {img: icon7, index: 7},
  ];

  const rows = t('trading-block-img', { returnObjects: true });

  return (
    <div className='text-image'>
      <div className='container'>
        {rows.map((item, index) => (
          <div className='item' 
            key={index}
            onMouseEnter={() => setActiveBlock(index)}
            onMouseLeave={() => setActiveBlock(null)} 
          >
            <div className='flex'>
              <div className='main-title'>{item.title}</div>
              <div className='main-text'>
                {item.content}
                <i className='icon'>
                  <img src={arrow} alt='icon' />
                </i>
              </div>
            </div>

            <div className={`absolute img-${index} ${activeBlock === index ? 'active' : ''}`}>
            <img src={icons[index].img} alt='' />
          </div>
          </div>
        ))}
      </div>

    </div>
  );
}

export default TextImage;
