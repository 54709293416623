import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import MainExp from '../Main_Experience/component';
import Trade from '../Trade/component';
import Join from '../Join/component';
import MarqueeBlock from '../Marquee/MarqueeBlock';
import BonusBlock from '../MainBonusBlock/component';
import MainSlider from '../MainSlider/component';
import SimpleSlider from '../MainSimpleSlider/component';
import MainBottomBlock from '../MainBottomBlock/component';

import imageRight from '../../assets/images/main_img_right.webp';
import image1 from '../../assets/images/simple-slide-1.webp';
import image2 from '../../assets/images/simple-slide-2.webp';
import image3 from '../../assets/images/simple-slide-3.webp';

const slides = [
  { 
    title: 'main-slider-second-title-1',
    blockTitle: 'main-slider-second-0',
    text1: 'main-slider-second-1',
    text2: 'main-slider-second-2',
    btnText: 'main-slider-second-btn',
    img: image1,
  },
  {
    title: 'main-slider-second-title-1',
    blockTitle: 'main-slider-second-0',
    text1: 'main-slider-second-1',
    text2: 'main-slider-second-2',
    btnText: 'main-slider-second-btn',
    img: image2,
  },
  {
    title: 'main-slider-second-title-1',
    blockTitle: 'main-slider-second-0',
    text1: 'main-slider-second-1',
    text2: 'main-slider-second-2',
    btnText: 'main-slider-second-btn',
    img: image3,
  }
];

function Main() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Fix Flex</title>
        <meta name="title" content="Welcome to Fix Flex - Where Financial Markets Await" />
        <meta property="og:title" content="Welcome to Fix Flex - Where Financial Markets Await" />
        <meta property="twitter:title" content="Welcome to Fix Flex - Where Financial Markets Await" />

        <meta name="description" content="Step into Fix Flex, the leading trading platform that emboldens you to venture into a universe of financial possibilities. Seize control of your trading expedition with our intuitive interface, state-of-the-art tools, and a diverse array of assets at your fingertips." />
        <meta property="og:description" content="Step into Fix Flex, the leading trading platform that emboldens you to venture into a universe of financial possibilities. Seize control of your trading expedition with our intuitive interface, state-of-the-art tools, and a diverse array of assets at your fingertips." />
        <meta property="twitter:description" content="Step into Fix Flex, the leading trading platform that emboldens you to venture into a universe of financial possibilities. Seize control of your trading expedition with our intuitive interface, state-of-the-art tools, and a diverse array of assets at your fingertips." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='ovf-hidden'>
        <Header />
        <MainExp 
          text1='main.display-1.title' 
          text2='main.display-1.subtitle' 
          text3='main.display-1.text' 
          textbtn='main.display-1.btn'  
          imageRight={imageRight}
        />
        <Join />
        <Trade />
        <MarqueeBlock />
        <MainSlider />
        <BonusBlock />
        <SimpleSlider slides={slides} />
        <MainBottomBlock />
        

        <Footer />
      </div>
    </HelmetProvider>
  )
}

export default Main;
