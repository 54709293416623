import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import MainExp from '../Main_Experience/component';
import MarqueeBlock from '../Marquee/MarqueeBlock';
import MainBottomBlock from '../MainBottomBlock/component';
import TextImage from '../TextImage/component';
import BonusBlock from '../TradingBonusBlock/component';

import imageRight from '../../assets/images/trading-1.webp';

function Trading() {
  return (
    <HelmetProvider>
      <Helmet>
      <title>Fix Flex · Trading assets</title>
        <meta name="title" content="Explore various account types at Fix Flex for your trading" />
        <meta property="og:title" content="Explore various account types at Fix Flex for your trading" />
        <meta property="twitter:title" content="Explore various account types at Fix Flex for your trading" />

        <meta name="description" content="Discover an extensive array of trading assets at Fix Flex. With offerings ranging from international stocks and forex to cryptocurrencies, commodities, indices, and beyond, our platform provides you with the chance to diversify your investment portfolio and capitalize on thrilling market prospects." />
        <meta property="og:description" content="Discover an extensive array of trading assets at Fix Flex. With offerings ranging from international stocks and forex to cryptocurrencies, commodities, indices, and beyond, our platform provides you with the chance to diversify your investment portfolio and capitalize on thrilling market prospects." />
        <meta property="twitter:description" content="Discover an extensive array of trading assets at Fix Flex. With offerings ranging from international stocks and forex to cryptocurrencies, commodities, indices, and beyond, our platform provides you with the chance to diversify your investment portfolio and capitalize on thrilling market prospects." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='trading-page ovf-hidden'>
        <Header />
        <MainExp
          text1='trading-0' 
          text2='trading-1' 
          text3='trading-2' 
          textbtn='trading-btn'  
          imageRight={imageRight}
        />
        <MarqueeBlock />
        <TextImage />
        <BonusBlock />

        <MainBottomBlock />

        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default Trading;
