import React from 'react';
import { useTranslation } from "react-i18next";
import Button from '../Button/Button';
import image from '../../assets/images/check.svg';

import './style.scss';

function BonusBlock() {
  const { t } = useTranslation();

  return (
    <div className='bonus-block'>
      <div className='container'>
        <div className='left'>
          <div className='main-title'>{t('bonus-1')}</div>
          <div className='main-text'>{t('bonus-2')}</div>
          <div className='main-text'>{t('bonus-3')}</div>
          <Button text={t('bonus-btn')}></Button>
        </div>
        <div className='img-wrap right'>
          <div className='main-title'>{t('bonus-4')}</div>
          <div className='with-icon'>
            <i className='icon'>
              <img src={image} />
            </i>
            <span>{t('bonus-5')}</span>
          </div>
          <div className='with-icon'>
            <i className='icon'>
              <img src={image} />
            </i>
            <span>{t('bonus-6')}</span>
          </div>
          <div className='with-icon'>
            <i className='icon'>
              <img src={image} />
            </i>
            <span>{t('bonus-6')}</span>
          </div>
        </div>
      </div>

    </div>
  );
}

export default BonusBlock;
