import React from "react";

import Marquee from "react-fast-marquee";
import { useTranslation } from "react-i18next";
import './style.scss';

function MarqueeBlock () {

  const { t, i18n } = useTranslation();
  const marquee1 = t('marquee-1', { returnObjects: true });
  const marquee2 = t('marquee-2', { returnObjects: true });

  return (
    <div className="merquee-block">
      <div className="block">
        <Marquee>
          {marquee1.map((item, index) => (
            <div key={index} className='merquee-item'>
              {item.title}
            </div>
          ))}
          {marquee1.map((item, index) => (
            <div key={index} className='merquee-item'>
              {item.title}
            </div>
          ))}
        </Marquee>
      </div>
      <Marquee direction="right">
        {marquee2.map((item, index) => (
          <div key={index} className='merquee-item'>
            {item.title}
          </div>
        ))}
        {marquee2.map((item, index) => (
          <div key={index} className='merquee-item'>
            {item.title}
          </div>
        ))}
      </Marquee>
    </div>
  )
}

export default MarqueeBlock;