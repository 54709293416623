import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import Button from '../Button/Button';
import arrow from '../../assets/images/acc-arrow.svg';
import './style.scss';

const TextAccordion = ({ content }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const { t } = useTranslation();

  const onTitleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const items = t(`${content.contentTab}`, { returnObjects: true });

  const renderedItems = items.map((item, index) => {
    const active = index === activeIndex ? 'active' : '';

    return (
      <div key={index} className={`item ${active}`}>
        <div 
          className={`title ${active}`} 
          onClick={() => onTitleClick(index)}
        >
          {item.title}
          <i className='icon'>
            <img src={arrow} alt='' />
          </i>
        </div>
        <div className={`content ${active}`}>
          <p>{item.content}</p>
        </div>
      </div>
    );
  });

  return <div className="accordion text-acc">
    <div className='container'>
      <div className='left'>
        {content.title1 ? (
            <div className='main-title'>{t(`${content.title1}`)}</div>
          ) : (
            ''
          )}
        {content.title2 ? (
            <div className='main-title'>{t(`${content.title2}`)}</div>
          ) : (
            ''
          )}
        {content.text1 ? (
            <div className='main-text marg-top'>{t(`${content.text1}`)}</div>
          ) : (
            ''
          )}
        {content.text2 ? (
            <div className='main-text'>{t(`${content.text2}`)}</div>
          ) : (
            ''
          )}
        <Button text={t('bonus-system-btn')} />
      </div>
      <div className='right'>
        {renderedItems}
      </div>
    </div>
  </div>;
};

export default TextAccordion;
