import React, { useEffect, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import $ from 'jquery';

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import { images } from "./imagesToLoad";

import Main from './components/pages/Main';
import Trading from './components/pages/Trading';
import AccounTypes from "./components/pages/AccounTypes";
import Fees from "./components/pages/Fees";
import BonusSystem from "./components/pages/BonusSystem";
import AboutUs from "./components/pages/AboutUs";

import './App.css';

function App() {
  const [ isLoading, setIsLoading ] = useState(true);

  const loadImage = (image) => {
    return new Promise((resolve, reject) => {
      const newImage = new Image();
      newImage.src = image;
      newImage.onload = () => resolve(image);
      newImage.onerror = (err) => reject(err);
    });
  };

  useEffect(() => {
    isLoading && $('body').addClass("ovf-hidden");
    Promise
      .all(images.map((image) => loadImage(image)))
      .then(() => setIsLoading(false))
      .catch((err) => {
        console.log("Failed to load images", err)
        setIsLoading(false);
      });
    // eslint-disable-next-line  
  }, []);

  useEffect(() => {
    !isLoading && $('body').removeClass("ovf-hidden");
    !isLoading && $('#loader').addClass("hide");
  }, [isLoading]); 

  return (
      <HelmetProvider>
        <Router>
          <Routes>
            <Route path="/:lang/" element={<Main />} />
            <Route path="/:lang/trading" element={<Trading />} />
            <Route path="/:lang/account-types" element={<AccounTypes />} />
            <Route path="/:lang/fees" element={<Fees />} />
            <Route path="/:lang/bonus-system" element={<BonusSystem />} />
            <Route path="/:lang/about" element={<AboutUs />} />
            <Route path='/*' element={<Navigate to='/en/' replace />} />
          </Routes>
        </Router>

      </HelmetProvider>
  );
}

export default App;
