import React from 'react';
import { useTranslation } from "react-i18next";
import imageBg from '../../assets/images/arrow-right.svg';
import './style.scss';

function Join() {
  const { t } = useTranslation();

  const joinbtns = t('joinbtns', { returnObjects: true });

  return (
    <div className='join'>
      <div className='container'>
        <div className='left'>
          {joinbtns.map((item, index) => (
            <div className='item' key={index}>
              <span>{item.title}</span>
              <i className='icon'>
                <img src={imageBg} alt='' />
              </i>
            </div>
          ))}
        </div>
        <div className='right'>
          <div className='main-title'>{t('join-title-1')}</div>
          <div className='main-subtitle'>{t('join-title-2')}</div>
          <div className='main-text'>{t('join-title-3')}</div>
        </div>
      </div>

    </div>
  );
}

export default Join;
