import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import FeesFirst from '../FeesFirst/component';
import FeesSlider from '../FeesSlider/component';
import MainBottomBlock from '../MainBottomBlock/component';

import image1 from '../../assets/images/fees/fees-1.webp';
import image2 from '../../assets/images/fees/fees-2.webp';
import image3 from '../../assets/images/fees/fees-3.webp';
import image4 from '../../assets/images/fees/fees-4.webp';
import image5 from '../../assets/images/fees/fees-5.webp';

const slides = [
  { 
    blockTitle: 'fees-1-title',
    text2: 'fees-1-title2',
    text1: 'fees-1-text',
    btnText: 'fees-btn',
    img: image1,
  },
  { 
    text2: 'fees-2-title2',
    blockTitle: 'fees-2-title',
    text1: 'fees-2-text',
    btnText: 'fees-btn',
    img: image2,
  },
  { 
    text2: 'fees-3-title2',
    blockTitle: 'fees-3-title',
    text1: 'fees-3-text',
    btnText: 'fees-btn',
    img: image3,
  },
  { 
    text2: 'fees-4-title2',
    blockTitle: 'fees-4-title',
    text1: 'fees-4-text',
    btnText: 'fees-btn',
    img: image4,
  },
  { 
    text2: 'fees-5-title2',
    blockTitle: 'fees-5-title',
    text1: 'fees-5-text',
    btnText: 'fees-btn',
    img: image5,
  },
];


function Fees() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Fix Flex · Fees</title>
        <meta name="title" content="Fix Flex's Transparent and Competitive Fee Structure" />
        <meta property="og:title" content="Fix Flex's Transparent and Competitive Fee Structure" />
        <meta property="twitter:title" content="Fix Flex's Transparent and Competitive Fee Structure" />

        <meta name="description" content="Experience transparency and competitiveness in our fee structure at Fix Flex. We provide a straightforward pricing model, ensuring you have a clear understanding of your trading costs." />
        <meta property="og:description" content="Experience transparency and competitiveness in our fee structure at Fix Flex. We provide a straightforward pricing model, ensuring you have a clear understanding of your trading costs." />
        <meta property="twitter:description" content="Experience transparency and competitiveness in our fee structure at Fix Flex. We provide a straightforward pricing model, ensuring you have a clear understanding of your trading costs." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='fees-page ovf-hidden'>
        <Header />
        <FeesFirst />

        <FeesSlider slides={slides} />

        <MainBottomBlock />
        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default Fees;
