import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from "react-i18next";
import Button from '../Button/Button';
import arrow from '../../assets/images/arrow-right.svg';
// import 'swiper/css';
// import 'swiper/css/navigation';
import './style.scss';

const AboutSlider = ({ slides }) => {
  const sliderRef = React.useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const { t, i18n } = useTranslation();

  const handleNextClick = () => {
    const nextIndex = activeIndex + 1;

    sliderRef.current.slickNext();

    if (nextIndex >= slides.length) {
      setActiveIndex(0);
    } else {
      setActiveIndex(nextIndex);
    }
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "60px",
    arrow: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          centerPadding: 0,
        },
      },
    ],
  };

  return (
    <div className='main-simple-slider about-slide'>
      <div className='container container-column'>
        <div className='text-block'>
            <div className='main-title'>{t('about-title')}</div>
            <div className='main-text'>{t('about-text1')}</div>
            <div className='main-text'>{t('about-text2')}</div>
            <Button text={t('about-btn')} />
        </div>
        <div className='slider-wrap'>
          <Slider ref={sliderRef} {...settings}>
            {slides.map((slide, index) => (
              <div key={index} className='wrap-block'>
                <img src={slide.img} />
                {slide.title ? (
                  <div className='slide-title'>{t(`${slide.title}`)}</div>
                ) : (
                  ''
                )}
                {slide.text ? (
                  <div className='slide-text'>{t(`${slide.text}`)}</div>
                ) : (
                  ''
                )}
              </div>
            ))}
          </Slider>
          
          <div className='pagin'>
            <button onClick={handleNextClick} className='slide-btn'>
              <span>{t('main-slider-second-more')}</span>
              <i className='icon'>
                <img src={arrow} />
              </i>
              </button>
            <div className='count'>
              <span>{activeIndex + 1}/</span>{slides.length}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSlider;

