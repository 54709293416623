import React from 'react';
import { useTranslation } from "react-i18next";
import Button from '../Button/Button';
import imageBg from '../../assets/images/fees/fees-bg.webp';
import imageBg1 from '../../assets/images/fees/bg-sm.webp';
import imageHand from '../../assets/images/fees/fees-hand.webp';
import icon from '../../assets/images/check.svg';

import './style.scss';

function FeesFirst() {
  const { t } = useTranslation();

  return (
    <div className='main-fees'>
      <div className='container'>
        <div className='img-wrap absolute'>
          <img src={imageBg} alt='Image' />
        </div>
        <div className='left'>
          <div className='with-icon'>
            <i className='icon'>
              <img src={icon} alt='' />
            </i>
            <div className='main-text'>{t('fees-main-0')}</div>
          </div>
          <div className='with-icon'>
            <i className='icon'>
              <img src={icon} alt='' />
            </i>
            <div className='main-text'>{t('fees-main-1')}</div>
          </div>
          <div className='with-icon'>
            <i className='icon'>
              <img src={icon} alt='' />
            </i>
            <div className='main-text'>{t('fees-main-2')}</div>
          </div>
          <div className='with-icon'>
            <i className='icon'>
              <img src={icon} alt='' />
            </i>
            <div className='main-text'>{t('fees-main-3')}</div>
          </div>
          <div className='with-icon'>
            <i className='icon'>
              <img src={icon} alt='' />
            </i>
            <div className='main-text'>{t('fees-main-4')}</div>
          </div>
          <div className='with-icon'>
            <i className='icon'>
              <img src={icon} alt='' />
            </i>
            <div className='main-text'>{t('fees-main-5')}</div>
          </div>
        </div>
        <div className='right'>
          <div className='main-title'>{t('fees-main-title')}</div>
          <div className='main-text'>{t('fees-main-text')}</div>
          <Button text={t('fees-main-btn')}></Button>
          <div className='img-wrap rel'>
            <img src={imageHand} alt='Image' className='img-abs' />
            <img src={imageBg1} alt='Image' className='img-reg' />
          </div>
        </div>
      </div>

    </div>
  );
}

export default FeesFirst;
