import React from 'react';
import { useTranslation } from "react-i18next";
import Button from '../Button/Button';
import image from '../../assets/images/trade_img.webp';
import './style.scss';

function Trade() {
  const { t } = useTranslation();

  return (
    <div className='trade'>
      <div className='container'>
        <div className='left'>
          <div className='main-title'>{t('trade-title')}</div>
          <div className='main-subtitle'>{t('trade-subtitle')}</div>
          <div className='main-text'>{t('trade-text')}</div>
          <Button text={t('trade-btn')}></Button>
        </div>
        <div className='right'>
          <img src={image} alt='' />
        </div>
      </div>

    </div>
  );
}

export default Trade;
