import React, { useState, useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade } from 'swiper/modules';
import { useTranslation } from "react-i18next";
import image1 from '../../assets/images/main-slider-1.webp';
import image2 from '../../assets/images/main-slider-2.webp';
import image3 from '../../assets/images/main-slider-3.webp';
import image4 from '../../assets/images/main-slider-4.webp';
import icon from '../../assets/images/check.svg';
import line from '../../assets/images/line.svg';
import 'swiper/css';
import "swiper/css/effect-fade";
import './style.scss';

const SliderWithTabs = () => {
  const swiperRef = useRef(null);
  const [activeTab, setActiveTab] = useState(0);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const { t, i18n } = useTranslation();

  const toggleTab = (index) => {
    setActiveTab(index);
  };

  const goToNextSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goToPrevSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  useEffect(() => {
    const swiperInstance = swiperRef.current.swiper;
    const handleSlideChange = () => {
      setActiveSlideIndex(swiperInstance.activeIndex);
    };

    swiperInstance.on('slideChange', handleSlideChange);

    return () => {
      swiperInstance.off('slideChange', handleSlideChange);
    };
  }, []);
  
  const slidesContent = [
    { tab0: {
      title: 'main-slider-tab-0',
      content1: 'main-slider-tab-content-0-0',
      content2: 'main-slider-tab-content-0-1',
      content3: 'main-slider-tab-content-0-2',
      content4: 'main-slider-tab-content-0-3',
      img: image1,
    }, tab1: {
      title: 'main-slider-tab-1',
      content1: 'main-slider-tab-content-1-0',
      content2: 'main-slider-tab-content-1-1',
      content3: 'main-slider-tab-content-1-2',
      content4: 'main-slider-tab-content-1-3',
      img: image2,
    } },
    { tab0: {
      title: 'main-slider-tab-0',
      content1: 'main-slider-tab-content-2-0',
      content2: 'main-slider-tab-content-2-1',
      content3: 'main-slider-tab-content-2-2',
      content4: 'main-slider-tab-content-2-3',
      img: image3,
    }, tab1: {
      title: 'main-slider-tab-1',
      content1: 'main-slider-tab-content-3-0',
      content2: 'main-slider-tab-content-3-1',
      content3: 'main-slider-tab-content-3-2',
      content4: 'main-slider-tab-content-3-3',
      img: image4,
    } },
  ];

  const headerLinks = t('links', { returnObjects: true });

  return (
    <div className='main-slider'>
      <div className='container'>
        <div className='left'>
          <span>{t('main-slider-0')}</span>
          <i className='line'>
            <img src={line} alt='' />
          </i>
        </div>
        <div className='top'>
          <div className='main-text'>{t('main-slider-1')}</div>
          <div className='flex'>
            <button onClick={goToPrevSlide} 
              className={`slide-btn ${activeSlideIndex === 0 ? 'active' : ''}`}>
              <i className='icon'></i>
              <span>{t('main-slider-btn-1')}</span>
            </button>
            <button onClick={goToNextSlide} 
              className={`slide-btn ${activeSlideIndex === slidesContent.length - 1 ? 'active' : ''}`}>
              <i className='icon'></i>
              <span>{t('main-slider-btn-2')}</span>
            </button>
          </div>
        </div>
        <Swiper
          ref={swiperRef}
          spaceBetween={50}
          modules={[EffectFade]} 
          effect="fade"
          slidesPerView={1}
        >
          {slidesContent.map((slide, index) => (
            <SwiperSlide key={index}>
              <div className='item'>

                <div className={`tab-content__item`} data-count={activeTab}>
                  <div className='main-title'>
                    {t(`${slide[`tab${activeTab}`].title}`)}
                  </div>
                  <div className='with-icon'>
                    <i className='icon'>
                      <img src={icon} alt='' />
                    </i>
                    <span>{t(`${slide[`tab${activeTab}`].content1}`)}</span>
                  </div>
                  <div className='with-icon'>
                    <i className='icon'>
                      <img src={icon} alt='' />
                    </i>
                    <span>{t(`${slide[`tab${activeTab}`].content2}`)}</span>
                  </div>
                  <div className='with-icon'>
                    <i className='icon'>
                      <img src={icon} alt='' />
                    </i>
                    <span>{t(`${slide[`tab${activeTab}`].content3}`)}</span>
                  </div>
                  <div className='with-icon'>
                    <i className='icon'>
                      <img src={icon} alt='' />
                    </i>
                    <span>{t(`${slide[`tab${activeTab}`].content4}`)}</span>
                  </div>
                </div>

                <div className='rigth'>
                  <div className='img-wrap'>
                    <img src={slide[`tab${activeTab}`].img} alt='' />
                  </div>
                  <div className='btn-wrap'>
                    <button onClick={() => toggleTab('0')} className={`slide-btn ${activeTab == 0 ? 'active' : ''}`}>
                      {t(`${slide.tab0.title}`)}
                    </button>
                    <button onClick={() => toggleTab('1')} className={`slide-btn ${activeTab == 1 ? 'active' : ''}`}>
                      {t(`${slide.tab1.title}`)}
                    </button>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default SliderWithTabs;
