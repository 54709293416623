import React from 'react';
import { useTranslation } from "react-i18next";
import Button from '../Button/Button';
import image from '../../assets/images/check.svg';

import './style.scss';

function TradingBonusBlock() {
  const { t } = useTranslation();

  return (
    <div className='bonus-block trading'>
      <div className='container'>
        <div className='left'>
          <div className='main-title'>{t('trading-3')}</div>
          <div className='main-text'>{t('trading-4')}</div>
          <div className='main-text'>{t('trading-5')}</div>
          <div className='desk_only'>
            <Button text={t('trading-11')} />
          </div>
        </div>

        <div className='img-wrap right'>
          <div className='with-icon'>
            <i className='icon'>
              <img src={image} />
            </i>
            <span>{t('trading-6')}</span>
          </div>
          
          <div className='with-icon'>
            <i className='icon'>
              <img src={image} />
            </i>
            <span>{t('trading-7')}</span>
          </div>
          <div className='with-icon'>
            <i className='icon'>
              <img src={image} />
            </i>
            <span>{t('trading-8')}</span>
          </div>
          <div className='with-icon'>
            <i className='icon'>
              <img src={image} />
            </i>
            <span>{t('trading-9')}</span>
          </div>
          <div className='with-icon'>
            <i className='icon'>
              <img src={image} />
            </i>
            <span>{t('trading-10')}</span>
          </div>

          <div className='mob_only'>
           <Button text={t('trading-12')}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TradingBonusBlock;