import React from 'react';
import { useTranslation } from "react-i18next";
import Button from '../Button/Button';
import image from '../../assets/images/main_bottom_bg.webp';

import './style.scss';

function MainBottomBlock() {
  const { t } = useTranslation();

  return (
    <div className='bottom-block'>
      <div className='container'>
        <div className='content'>
          <div className='img-wrap absolute'>
            <img src={image} alt='' />
          </div>
          <div className='main-title'>{t('main-last-1')} <span>{t('main-last-2')}</span></div>
          <div className='main-subtitle'>{t('main-last-3')}</div>
          <div className='main-text'>{t('main-last-4')}</div>
          <Button text={t('main-last-btn')}></Button>
        </div>
      </div>
    </div>
  );
}

export default MainBottomBlock;
