import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from "react-i18next";
import Button from '../Button/Button';
import arrow from '../../assets/images/arrow-right.svg';
import 'swiper/css';
import 'swiper/css/navigation';
import './style.scss';

const SimpleSlider = ({ slides }) => {
  const sliderRef = React.useRef(null);
  const [activeIndex, setActiveIndex] = useState(1);
  const { t, i18n } = useTranslation();
  const isMobile = window.innerWidth < 768;

  const goToNextSlide = () => {
    const nextIndex = activeIndex + 1;

    if (nextIndex >= slides.length) {
      sliderRef.current.slickGoTo(0);
      setActiveIndex(0);
    } else {
      sliderRef.current.slickGoTo(nextIndex);
      setActiveIndex(nextIndex);
    }
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    centerPadding: 0,
    beforeChange: (current, next) => setActiveIndex(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <div className='main-simple-slider'>
      <div className='container container-column'>
        <div className='text-block'>
          {slides[activeIndex].blockTitle ? (
            <div className='main-title'>{t(`${slides[activeIndex].blockTitle}`)}</div>
          ) : (
            ''
          )}
          {slides[activeIndex].text1 ? (
            <div className='main-text'>{t(`${slides[activeIndex].text1}`)}</div>
          ) : (
            ''
          )}
          {slides[activeIndex].text2 ? (
            <div className='main-text'>{t(`${slides[activeIndex].text2}`)}</div>
          ) : (
            ''
          )}
          <Button text={t(`${slides[activeIndex].btnText}`)} />
        </div>
        <div className='slider-wrap'>
          <Slider ref={sliderRef} {...settings}>
            {slides.map((slide, index) => (
              <div key={index} className={`wrap-block ${activeIndex === index ? 'active' : ''}`}>
                <img
                  src={slide.img}
                  alt=''
                  style={{
                    width: activeIndex === index ? '100%' : '70%',
                    transition: 'width 0.3s ease',
                  }}
                />
                {slide.title ? (
                  <div className='slide-title'>{t(`${slide.title}`)}</div>
                ) : (
                  ''
                )}
              </div>
            ))}
          </Slider>
          
          <div className='pagin'>
            <button onClick={isMobile ? goToNextSlide : () => settings.beforeChange(activeIndex, (activeIndex + 1) % slides.length)} className='slide-btn'>
              <span>{t('main-slider-second-more')}</span>
              <i className='icon'>
                <img src={arrow} />
              </i>
              </button>
            <div className='count'>
              <span>{activeIndex + 1}/</span>{slides.length}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleSlider;

