import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import arrow from '../../assets/images/acc-arrow.svg';
import './style.scss';

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const { t } = useTranslation();

  const onTitleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const items = t('account_accordion', { returnObjects: true });

  const renderedItems = items.map((item, index) => {
    const active = index === activeIndex ? 'active' : '';

    return (
      <div key={index}>
        <div 
          className={`title ${active}`} 
          onClick={() => onTitleClick(index)}
        >
          {item.title}
          <i className='icon'>
            <img src={arrow} alt='' />
          </i>
        </div>
        <div className={`content ${active}`}>
          <p>{item.content}</p>
        </div>
      </div>
    );
  });

  return <div className="accordion">
    <div className='container'>{renderedItems}</div>
  </div>;
};

export default Accordion;
