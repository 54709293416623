import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import './style.scss';


const TableTabs = () => {
  const [activeColumn, setActiveColumn] = useState(1);
  const { t, i18n } = useTranslation();

  const data = t('tableTabs', { returnObjects: true });

  const titles = Object.values(data[0]);

  const columns = Object.keys(data[0]);

  return (
    <div className='table-tabs'>
      <div className='container'>
        <div className="responsive-table">
          <div className="tabs">
            {titles.map((item, index) => (
              <button
                key={index}
                className={`tab ${activeColumn === index ? 'active' : ''}`}
                onClick={() => setActiveColumn(index)}
              >
                {item}
              </button>
            ))}
          </div>

          <div className='t-table'>
            <div className='t-head flex'>
              {titles.map((item, index) => (
                <div key={index} className={`table-row block-${index}`}>
                  {item}
                </div>
              ))}
            </div>
            <div className='t-body'>
              {data.map((row, rowIndex) => (
                <div key={rowIndex} className={`flex`}>
                  {columns.map((column, index) => (
                    <div key={index} className={`table-row block-${index} ${index === activeColumn ? 'active-column' : ''}`}>
                      {row[column]}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableTabs;
