import React from 'react';
import { useTranslation } from "react-i18next";
import Button from '../Button/Button';
import imageBg from '../../assets/images/main_main.webp';
import './style.scss';

function MainExp({ text1, text2, text3, textbtn, imageRight  }) {
  const { t } = useTranslation();

  return (
    <div className='main-exp'>
      <div className='img-wrap absolute'>
        <img src={imageBg} alt='Image' />
      </div>
      <div className='container'>
        <div className='left'>
          <div className='main-title'>{t(text1)}</div>
          <div className='main-subtitle'>{t(text2)}</div>
          <div className='main-text'>{t(text3)}</div>
          <Button text={t(textbtn)}></Button>
        </div>
        <div className='right'>
          <div className='orange-block'></div>
          <div className='img-wrap'>
            <img src={imageRight} alt='Image' />
          </div>
        </div>
      </div>

    </div>
  );
}

export default MainExp;
