import React from 'react';
import { useTranslation } from "react-i18next";
import Button from '../Button/Button';

import './style.scss';

function ExtBonus() {
  const { t } = useTranslation();

  return (
    <div className='bonus-ext'>
      <div className='container'>
        <div className='left'>
          <div className='main-title'>
            {t('ext-bonus-0')}
          </div>
          <div className='main-text'>
            {t('ext-bonus-1')}
          </div>
          <div className='main-subtitle'>
            {t('ext-bonus-2')}
          </div>
          <div className='main-text'>
            {t('ext-bonus-3')}
          </div>
          <div className='main-subtitle'>
            {t('ext-bonus-4')}
          </div>
          <div className='main-text'>
            {t('ext-bonus-5')}
          </div>
        </div>
        <div className='right'>
        <div className='main-text'>
            {t('ext-bonus-6')}
          </div>
          <Button text={t('ext-bonus-btn')}></Button>
        </div>
      </div>

    </div>
  );
}

export default ExtBonus;
